/*-----------------------------------
 * MP hayabusa Customize
 * [このファイルの役割]
 * ・プラグインの読み込み
 * ・URLによるモジュールの実行
 * ・サイト設定
 * ・URLによるCSS読み込み設定
 *-----------------------------------*/

import 'jquery.easing'
import './plugins/plugins'
import './plugins/utility'
import INIT from './modules/init'

// ページ読み込み
import HOME from './page/home'

/*****
 * サイト設定
 */
window.siteinfo = {
  viewport:         'width=1200',
}

/*****
 * サイト初期化
 */
INIT()

switch(location.pathname.replace('main.php','')){
  // トップページ
  case '/': HOME()
}
