import VIEWPORTS from '../plugins/viewports'
import PARTSLOADER from '../plugins/partsloader'
import CONTENT_TITLE from '../plugins/contentTitle'
// import FULLCALENDAR from '../plugins/fullcalendar'

// IE11でSVGの外部参照を有効にする
import 'svgxuse'

export default function INIT() {
  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'contentFooter': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'footer': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'mapScript': new $.Deferred, // googlemapAPIのURLパラメータでresolveします
  }

  VIEWPORTS() // Viewportの置き換え

  //header.html, footer.html読み込み
  PARTSLOADER([
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      target: 'footer #footerNav',
      method:'replaceWith'
    },
    {
      name: 'contentFooter',
      loadhtml: '/asset/html/contentFooter.html',
      target: 'footer,#footer.ui-footer',
      method:'before',
      callback(r,_){
        $('.homeContentslist').insertBefore('#footer');
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      target: 'header',
      method:'replaceWith',
      callback(r,_){
        $('header .p-gnav').currentMenu({mode:1,default:999});
      }
    }
  ])
  // プライバシー・会社概要・サイトマップのコンテンツタイトル
  CONTENT_TITLE({
    'sitemap':'サイトマップ',
    'privacy':'プライバシーポリシー',
    'profile':'会社概要'
  })

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // カレントメニュー
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    })
    // ページトップリンクをスクロール量で表示するサンプル
    $.scrollspy({
      trigger:200,
      onBefore(/* pos */) {
        $('.p-gotop__wrap').addClass('hidden');
      },
      onAfter() {
        $('.p-gotop__wrap').removeClass('hidden');
      }
    })
  })

  // window.load['contentFooter'].done(() => {
  //   // fullcalendar挿入
  //   FULLCALENDAR(document.querySelectorAll('[data-plugin="calendar"]'),{
  //     googleCalendarApiKey: 'AIzaSyAp7Q8UGPJkuCJnRnq4M9PZgilB1XTkVQM',
  //     contentHeight: 500,
  //     eventSources: [
  //       {
  //         googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
  //         className: 'holiday'
  //       }
  //     ]
  //   })
  // })

}