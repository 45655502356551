import 'slick-carousel'
import GET_ARTICLES from '../plugins/getArticles';

const [w] = [window]

export default function HOME() {

  $(() => {

    w.load['block-guideline'].done(() => {
      GET_ARTICLES('.p-block-guideline [data-plugin="getArticles"]',{
        makeItem(item,opt) {
          return $(`
            <a href="${item.href}" class="p-block-guideline__item">
              <span class="date">${item.date}</span>
              <span class="subject">${item.title}</span>
            </a>`)[0]
        }
      })
    })
  
    w.load['block-member'].done(() => {
      GET_ARTICLES('.p-block-member [data-plugin="getArticles"]').done(() => {
        $('.p-block-member__list').slick({
          autoplay: true,
          fade: false,
          speed: 200,
          pauseOnHover: false,
          pauseOnFocus: false,
          centerMode: true,
          arrows: false,
          infinite: true,
          variableWidth: true,
          swipeToSlide:true,
          slidesToShow: 4,
          responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            }
          }]
        })
      })
    })
  
  
  });
}